import logo from "./grass-light.svg";
import Progress from "./Progress";


export default function Header({currentStep, numSteps}) {
    const margin = "-10px";
    return (<>
        <div className="row mb-2">
            <div className="col-12 text-center bg-success">
                <strong className="text-white">GRASS MASTER HYDROSEED</strong>
            </div>
        </div>
        <div className="row g-0">
            <div className="col-3 text-center">
                <a className="text-dark text-decoration-none" href="https://www.grassmaster.com">
                    <img src={logo} style={{marginTop: margin, marginBottom: margin}} className="img-fluid"
                         width="100"
                         alt="logo"/>

                </a>
            </div>
            <div className="col-9">
                <div className="mt-4">
                    <Progress currentStep={currentStep} numSteps={numSteps}/>
                </div>
            </div>
        </div>


    </>)
}