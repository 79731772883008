import './progress.css';

export default function Progress({currentStep, numSteps}){
    const divisor = numSteps - 1;
    const progressPercentage = ((currentStep - 1) / divisor) * 100;
    return (<>
            <div className="progress px-1" style={{height: "3px"}}>
                <div className="progress-bar" role="progressbar" style={{width: `${progressPercentage}%`}}
                     aria-valuenow="0"
                     aria-valuemin="0"
                     aria-valuemax="100"></div>
            </div>
            <div className="step-container d-flex justify-content-between">
                {Array.from({length: numSteps}, (_, i) => i + 1).map((step) => (
                    <div key={step} className={`step-circle ${currentStep === step ? "active" : ""}`}>
                        {step}
                    </div>
                ))}
            </div>
        </>
    )
}