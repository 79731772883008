export default function Footer({currentStep, prevStep, formValidity}) {
    if (currentStep > 4) {
        return "";
    }

    const showDebug = false;

    return (
        <>
            <div className="row g-1 mb-2">
                <div className="col-6 d-grid">
                    <button
                        type="button"
                        className={`btn  btn-sm ${
                            currentStep > 1 ? "btn-outline-primary" : "btn-secondary disabled"
                        }`}
                        onClick={prevStep}
                    >
                        Previous
                    </button>
                </div>

                <div className="col-6 d-grid">
                    <button
                        type="submit"
                        className={`btn btn-sm ${formValidity ? "btn-primary" : "btn-outline-primary"}`}
                    >
                        Next
                    </button>
                </div>
            </div>
            {showDebug ? (<div className="row g-1">
                <div className="col">
                    <h1>form:{JSON.stringify(formValidity)}</h1>
                </div>
            </div>) : ""}
        </>
    );
}
