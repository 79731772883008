import React, {useState} from "react";
import FinalStep from "./components/FinalStep";
import Footer from "./components/Footer";
import Header from "./components/Header";
import StepAddress from "./components/StepAddress";
import StepArea from "./components/StepArea";
import StepSeedMixture from "./components/StepSeedMixture";
import StepContactInformation from "./components/StepContactInformation";


// eslint-disable-next-line
const TESTDATA = {
    valid: true,
    currentStep: 1,
    address: "12018",
    city: "kirkland",
    state: "WA",
    zip: "98033",
    area: "30000",
    unit: "sqft", // "acres"
    seedmix: "lawn",
    comments: "I need shady / weed resist / awesome",
    name: "blair",
    email: "blair@foobutter.com",
    phone: "4258291130",
};
// eslint-disable-next-line
const DEFAULTDATA = {
    valid: false,
    currentStep: 1,
    address: "",
    city: "",
    state: "",
    zip: "",
    area: "",
    unit: "sqft", // "acres"
    seedmix: "",
    comments: "",
    name: "",
    email: "",
    phone: "",
};

const NUM_STEPS = 4;

export default function App() {
    const [errorMessage, updateError] = useState(null);
    const [formData, setFormData] = useState(DEFAULTDATA);
    const handleChange = (e) => {
        const formElement = e.target?.form;
        if (formElement && typeof formElement.checkValidity === 'function') {
            setFormData({...formData, [e.target.name]: e.target.value, valid: formElement.checkValidity()});
        } else {
            setFormData({...formData, [e.target.name]: e.target.value});
        }
    };

    const nextStep = () => {
        setFormData({...formData, currentStep: formData.currentStep + 1, valid: false});
    };

    const prevStep = () => {
        setFormData({...formData, currentStep: formData.currentStep - 1, valid: true});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formElement = e.target;
        const isValid = formElement.checkValidity();
        if (isValid) {
            updateError(null);
            nextStep();
        } else {
            updateError("Please Fill Out All Form Fields");
        }
        // Submit your form data to your server or API endpoint
    };

    const currentStep = formData.currentStep;
    const formValidity = formData.valid;

    return (
        <>
            <div style={{maxWidth: "500px", margin: 'auto', backgroundColor: 'white'}} className="container border border-success border-5">
                <div className="row">
                    <div className="col-12">
                        <Header numSteps={NUM_STEPS} currentStep={currentStep}/>
                        {currentStep >= 1 && currentStep <= 4 && (
                            <form onSubmit={handleSubmit}>
                                <div style={{height: "100%", minHeight: "30vh"}}>
                                {currentStep === 1 && (
                                        <StepArea formData={formData} handleChange={handleChange}
                                                  setFormData={setFormData}/>)}
                                    {currentStep === 2 && (
                                        <StepAddress formData={formData} handleChange={handleChange} setFormData={setFormData}/>)}
                                    
                                    {currentStep === 3 && (
                                        <StepSeedMixture formData={formData} handleChange={handleChange}/>)}
                                    {currentStep === 4 && (<StepContactInformation formData={formData}
                                                                                   handleChange={handleChange}/>)}
                                </div>
                                <Footer
                                    currentStep={currentStep}
                                    formValidity={formValidity}
                                    prevStep={prevStep}
                                />
                            </form>
                        )}
                        {currentStep === 5 ? (<FinalStep
                            currentStep={currentStep}
                            formData={formData}
                            setFormData={setFormData}
                        />) : ""}
                        
                    </div>
                </div>

                {errorMessage ? (
                    <p className="alert alert-warning">{errorMessage}</p>
                ) : (
                    ""
                )}

            </div>
        </>
    );
}

// eslint-disable-next-line
function googleReady(callback){
    if (window.google) {
        callback(true)
    } else {
        function MapCallback() {
            callback(true);
        }
        window.MapCallback = MapCallback;
    }
}


