import { useForm } from '@formspree/react';

export default function FinalStep({currentStep, formData, setFormData}) {
    const [state, handleSubmit] = useForm("mvoeweyy");

    if (state.succeeded) {
        return <p>Thanks for joining!</p>;
    }
    return (
        <div className="carousel-item active">
            <form onSubmit={handleSubmit}>
                <h3>Confirm your details</h3>
                <label htmlFor="address">Address:</label>
                <input
                    id="address"
                    name="address"
                    type="text"
                    className="form-control form-control-sm"
                    value={`${formData.address}, ${formData.city}, ${formData.state} ${formData.zip}`}
                    readOnly
                    disabled
                />
                <label htmlFor="area">Area:</label>
                <input
                    id="area"
                    type="text"
                    value={`${formData.area} ${formData.unit}`}
                    className="form-control form-control-sm"
                    readOnly
                    disabled
                />

                <label htmlFor="seedmix">Seed Mix:</label>
                <input
                    id="seedmix"
                    type="text"
                    value={`${formData.seedmix}: ${formData.comments}`}
                    className="form-control form-control-sm"
                    readOnly
                    disabled
                />

                <label htmlFor="name">Name:</label>
                <input
                    id="name"
                    type="text"
                    value={formData.name}
                    className="form-control form-control-sm"
                    readOnly
                    disabled
                />

                <label htmlFor="email">Email:</label>
                <input
                    id="email"
                    type="text"
                    value={formData.email}
                    className="form-control form-control-sm"
                    readOnly
                    disabled
                />

                <label htmlFor="phone">Phone:</label>
                <input
                    id="phone"
                    type="text"
                    value={formData.phone}
                    className="form-control form-control-sm"
                    readOnly
                    disabled
                />
                <div className="d-flex mt-2 w-100">
                    <button type="submit" disabled={state.submitting} className="btn w-50 btn-lg btn-primary">
                        Get Your Quote!
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            setFormData({...formData, currentStep: 1, valid: true});
                        }}
                        className="btn btn-lg btn-outline-warning w-50"
                    >
                        Need to Fix?
                    </button>
                </div>
                <hr/>
                <p className="text-center">
                    <a href="https://www.grassmaster.com/">Go Back Home</a>
                </p>
            </form>
        </div>
    );
}
