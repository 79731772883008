import {useState, useRef, useEffect} from "react";

function stateFromComponents(components) {
    let street_number, route, city, state, zip, newState = {};
    for (const component of components) {
        const componentType = component.types[0];
        // eslint-disable-next-line
        switch (componentType) {
            case "street_number":
                street_number = component.long_name;
                break;
            case "route":
                route = component.long_name;
                break;
            case "locality":
                city = component.long_name;
                break;
            case "administrative_area_level_1":
                state = component.short_name;
                break;
            case "postal_code":
                zip = component.long_name;
                break;
        }
    }

    if (street_number || route) {
        newState["address"] = [street_number, route].join(" ");
    }
    if (city) {
        newState["city"] = city;
    }
    if (state) {
        newState["state"] = state;
    }
    if (zip) {
        newState["zip"] = zip;
    }
    newState["autocomplete"] = false;
    return newState;
}

export default function StepAddress({formData, handleChange, setFormData}) {
    const [loaded, setLoaded] = useState(false);
    const autoCompleteRef = useRef();
    const inputRef = useRef();


    useEffect(() => {
        const initAutocomplete = () => {
            if (!loaded) {
                autoCompleteRef.current = new window.google.maps.places.Autocomplete(
                    inputRef.current,
                    {componentRestrictions: {country: ["us"]}}
                );
                autoCompleteRef.current.addListener("place_changed", (e) => {
                    const place = autoCompleteRef.current.getPlace();
                    const newState = stateFromComponents(place.address_components);
                    setFormData({...formData, ...newState});
                });
                setLoaded(true);
            }
        };
        window.initAutocomplete = initAutocomplete;

        const loadScript = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAEh2lbZ40rmzJsjGOT9V9N0yX-IICIWdA&loading=async&libraries=places";
                script.async = true;
                script.defer = true;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        if (!window.google) {
            loadScript().then(initAutocomplete).catch(console.error);
        } else {
            initAutocomplete();
        }
    }, [loaded, formData, setFormData]);

        return (
            <div>
                <label>Quote/Job/Project Address:</label>
                <div className="input-group">
                    <span className="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg>
                    </span>
                <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    ref={inputRef}
                    className="form-control"
                    required
                    placeholder={loaded ? "Where are we spraying" : "Loading..."}
                />
                </div>
                <div className="input-group">
                    <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className="form-control w-50"
                        required
                        placeholder="City"
                    />
                    <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        className="form-control"
                        required
                        placeholder="State"
                    />
                    <input
                        type="text"
                        name="zip"
                        value={formData.zip}
                        onChange={handleChange}
                        className="form-control"
                        required
                        placeholder="Zipcode"
                        pattern="^\d{5}(-\d{4})?$"
                    />

                </div>


            </div>
        )
}