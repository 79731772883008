import React from "react";

export default function StepContactInformation({formData, handleChange}) {
    return (<div>
        <label>*Your Name:</label>
        <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-control"
            required
        />
        <label>*Email:</label>
        <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-control"
            required
        />
        <label>*Phone:</label>
        <input
            type="tel"
            name="phone"
            value={formData.phone}
            className="form-control"
            onChange={handleChange}
            required
        />
    </div>)
}