
export default function StepSeedMixture({formData, handleChange}) {
    return (<div>
        <label>Seed Mixture Preference:</label>
        <div className="form-check">
            <input
                className="form-check-input"
                type="radio"
                name="seedmix"
                id="lawn"
                value="lawn"
                checked={formData.seedmix === "lawn"}
                onChange={handleChange}
                required
            />
            <label className="form-check-label" htmlFor="lawn">
                Lawn Mix
            </label>
        </div>
        <div className="form-check">
            <input
                className="form-check-input"
                type="radio"
                name="seedmix"
                id="erosion"
                value="erosion"
                checked={formData.seedmix === "erosion"}
                onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="erosion">
                Erosion Control
            </label>
        </div>
        <div className="form-check">
            <input
                className="form-check-input"
                type="radio"
                name="seedmix"
                id="custom"
                value="custom"
                checked={formData.seedmix === "custom"}
                onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="custom">
                Custom
            </label>
        </div>
        <label>Comments:</label>
        <input type="text" name="comments" value={formData.comments} className="form-control" onChange={handleChange}/>
    </div>)
}