

export default function StepArea({formData, handleChange}) {
    const unit = formData.unit === 'acres' ? 'acres' : 'sqft';


    return (
        <div>
            <label>Seed Area Square Footage:</label>
            <div className="btn-group w-100" role="group"
                 aria-label="Basic radio toggle button group">
                <input type="radio"
                       className="btn-check"
                       name="unit"
                       value="sqft"
                       onChange={handleChange}
                       id="btnradio1"
                       checked={unit === 'sqft'}/>
                <label className="btn btn-outline-success w-50"
                       htmlFor="btnradio1">Square Foot</label>
                <input type="radio" className="btn-check"
                       name="unit" id="btnradio2"
                       value="acres"
                       onChange={handleChange}
                       checked={unit === 'acres'}
                />
                <label className="btn btn-outline-success w-50"
                       htmlFor="btnradio2">Acres</label>
            </div>
            <div className="input-group mb-3 w-100">
                <input
                    type="tel"
                    name="area"
                    value={formData.area}
                    onChange={handleChange}
                    required
                    className="form-control text-center w-75"
                    placeholder="e.g., 5000, 10K, 1/2 acre"
                />
                <span className="input-group-text w-25 text-center"
                      id="basic-addon2">{unit.toUpperCase()}</span>
            </div>
            <ShowWarning area={formData.area||0} unit={formData.unit}/>
        </div>
    )
}

function ShowWarning({area, unit}) {
    const totalSQFT = (parseFloat(area) * (unit === 'acres' ? 43560 : 1)).toFixed(0);

    // 1. empty area / please enter area
    if (!area) return (<div className={"lead alert alert-warning"}>We need your estimated square footage!</div>)
    // 2. area < 500 / are you sure this looks small
    if (totalSQFT < 500) return (<div className={"lead alert alert-warning"}>Are you sure only {totalSQFT}-SQFT?</div>)
    // 3. area > 500 / looks great
    return <div className={"lead alert alert-success"}>{totalSQFT}-SQFT Looks Good!</div>
}

// eslint-disable-next-line
function LegalSpeak(){
    return (<p><small>Pricing is subject to </small></p>)
}
